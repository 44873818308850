<template>
  <div class="liveLottery">
    <div v-title
         data-title="幸运转盘"></div>

    <!-- <van-nav-bar
      title="真人闯关"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-turntable.jpg`" />
    </div>

    <div class="turntable-box">
      <img :src="`${imgUrl}pcbb/turntable-bg.png`"
           class="bgimg" />

      <div class="turntable">
        <img :src="datalist.img"
             class="zhuanpanimg"
             ref="rotImg"
             style="transform:rotate(270deg)"
             v-if="datalist.img" />
        <img :src="`${imgUrl}pcbb/turntable.png`"
             class="zhuanpanimg"
             ref="rotImg"
             style="transform:rotate(270deg)"
             v-else />

        <img :src="`${imgUrl}pcbb/turntable-btn.gif`"
             class="turntablebtn"
             @click="getcountindex" />
        <!-- @click="getcountindex" -->

        <div class="prizedrawing">
          剩余 {{ datalist.chance || 0 }} 次抽奖机会
        </div>
      </div>

      <div class="rule-big-box"
           style="padding-top:95px;margin: -20px 1px 0 1px;">
        <div class="rule-top-img">
          <img src="../assets/img/pcbb/rules_1.png"
               v-if="cueTheme == 'dattheme'" />
          <img :src="`${imgUrl}pcbb/rules.png`"
               v-else />
        </div>
        <div class="rule-middle-img">
          <!-- 活动条件 -->
          <div class="rule-condition">
            <div class="rule-condition-title"></div>

            <div class="activity_rules">
              <ul>
                <li style="display:flex">
                  <span>1.</span>
                  <div>
                    活动期间有过一笔
                    <em style="font-style: normal;display: contents;color: red;">
                      >{{ datalist.dpt_limit }} </em>元存款获得一次机会
                  </div>
                  <div class="activebtn activebtn2"
                       v-if="datalist.dpt_cs == 0">
                    未满足
                  </div>
                  <div class="activebtn "
                       v-else-if="datalist.dpt_cs == 1 || datalist.dpt_cs == 2">
                    已获取
                  </div>
                </li>
                <li style="display:flex">
                  <span>2.</span>
                  <div>
                    活动期间产生<em style="font-style: normal;display: contents;color: red;">
                      >{{ datalist.zbeat_limit }} </em>元的OB体育有效流水获得一次机会
                  </div>
                  <div class="activebtn activebtn2"
                       v-if="datalist.zbeat_cs == 0">
                    未满足
                  </div>
                  <div class="activebtn "
                       v-else-if="datalist.zbeat_cs == 1 || datalist.zbeat_cs == 2">
                    已获取
                  </div>
                </li>
                <li style="display:flex">
                  <span>3.</span>
                  活动期间产生<em style="font-style: normal;display: contents;color: red;">
                    >{{ datalist.bbeat_limit }} </em>元的沙巴体育有效流水获得一次机会
                  <div class="activebtn activebtn2"
                       v-if="datalist.bbeat_cs == 0">
                    未满足
                  </div>
                  <div class="activebtn "
                       v-else-if="datalist.bbeat_cs == 1 || datalist.bbeat_cs == 2">
                    已获取
                  </div>
                </li>
                <li style="display:flex">
                  <span>4.</span>
                  活动期间产生<em style="font-style: normal;display: contents;color: red;">
                    >{{ datalist.dbeat_limit }} </em>元的抖音体育有效流 水获得一次机会
                  <div class="activebtn activebtn2"
                       v-if="datalist.dbeat_cs == 0">
                    未满足
                  </div>
                  <div class="activebtn "
                       v-else-if="datalist.dbeat_cs == 1 || datalist.dbeat_cs == 2">
                    已获取
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 活动详情 -->
          <div class="rule-detail">
            <div class="rule-detail-title"></div>
            <div class="activity_rules">
              <ul>
                <li>
                  <span>1.</span>
                  完成任务栏内的指定任务后获得转盘机会
                </li>
                <li>
                  <span>2.</span>
                  转盘机会只在活动期间内生效，过期则清空次数
                </li>
                <li>
                  <span>3.</span>
                  转盘抽取后奖励自动发放指至账户，{{
                    datalist.water_limit
                  }}倍流水出款
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="rule-bottom-img"></div>
      </div>
    </div>

    <van-dialog v-model="showturntable"
                title="中奖提示"
                confirmButtonColor="#2A6AFD">
      <div class="content-text">{{ contetntext }}</div>
    </van-dialog>
  </div>
</template>

<script>
import { getdata, Actapply } from '../network/api'
import { Toast } from 'vant'

// var parmsObj
// window.msgFromUniapp = function(arg) {
//   let msg = JSON.parse(JSON.stringify(arg))
//   parmsObj = msg
// }
export default {
  data() {
    return {
      rotate: 0,
      resetRotate: 0,
      hitId: 1, // 1-13
      drawStatus: false,
      q: '',
      bigimg: '',
      showturntable: false,
      contetntext: '',
      count: 3,
      datalist: {},
      countindex: 0,
      hitAre: [],
      itemrotate: 30,
      cueTheme: '',
    }
  },
  created() {
    // parmsObj = this.countindex
  },
  // watch: {
  //   countindex: (news, olds) => {
  //     if (news) {
  //       console.log('news',news)
  //       this.hitAre.forEach((v, i) => {
  //         if (v.bonus_money == news) {
  //           this.toDraw(i)
  //       console.log('news+i',i)

  //         }
  //       })
  //     }
  //   }
  // },
  async mounted() {
    let that = this
    this.cueTheme = window.localStorage.getItem('cueTheme')

    await this.$nextTick()
    this.getdata()

    let evenTransition = this.whichTransitionEvent()
    var img = this.$refs.rotImg
    // 监听 动画结束
    img.addEventListener(evenTransition, this.tranHand, false)

    window.scrollTo(0, 0)

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'turntable') {
          if (e.data.status == 1) {
            that.hitAre.forEach((v, i) => {
              if (v.bonus_money == e.data.bonus_money) {
                that.toDraw(i)
              }
            })
          }
        }
      })
    }
  },
  methods: {
    msgFrom() {
      let that = this

      window.msgFromUniapp = function (arg) {
        that.hitAre.forEach((v, i) => {
          if (v.bonus_money == JSON.stringify(arg)) {
            that.toDraw(i)
          }
        })
      }
    },
    tranHand() {
      var img = this.$refs.rotImg
      let that = this
      // 复位
      that.resetRotate = that.rotate > 360 ? that.rotate % 360 : 0
      img.style.transition = 'none 0s ease 0s'
      img.style.transform = `rotate(${that.resetRotate}deg)`
      that.contetntext = `抽奖结果【 ${that.hitAre[that.hitId].bonus_money
        } 】彩金`

      that.showturntable = true
      that.drawStatus = false
    },
    getdata() {
      let data = '?type=turntable'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
          this.hitAre = res.message.list
          this.msgFrom()
          this.itemrotate = 360 / this.hitAre.length
        }
      })
    },
    start() {
      this.$refs.rotImg.style.transition = 'all 3s ease 0s'
      this.$refs.rotImg.style.transform = `rotate(${this.rotate}deg)`
    },

    getcountindex() {
      // if (this.hitAre == [] || !this.hitAre) {
      //   this.showturntable = true

      //   this.contetntext = `抽奖维护中，有疑问请联系客服`
      //   return
      // }
      // if (this.datalist.chance == 0) {
      //   this.showturntable = true

      //   this.contetntext = `抽奖机会不足,活动期间可以做任务获取哦！`
      //   return
      // }
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'turntable',
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 申请
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'turntable'
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'getcountindex',
            },
          })
        }
      })
    },

    toDraw(countindex) {
      if (this.drawStatus) {
        console.log('正在抽奖中')
        return
      }
      // 标记状态
      this.drawStatus = true
      /**
       * 圆盘共 10 份 每份 36度, 停位置(id)度数 (id - 1)*36
       * 基数 3圈 360*4
       * this.rotate 当前角度
       * **/
      let reset = 360 * 8

      // let idx = countindex
      let idx = countindex

      //1开始 12结束
      // this.getRandomInt(1, 13)
      //  this.getRandomInt(1, 13)
      // 设置命中
      this.hitId = idx

      this.datalist.chance--
      // 需要多转角度
      let addRotate = this.resetRotate > 0 ? 360 - this.resetRotate : 0
      // 总共角度
      let allRotate = this.rotate + idx * this.itemrotate + reset + addRotate
      // 角度限制
      this.rotate = this.setRotate(allRotate)

      this.start()
    },
    // 递归计算角度 不超过 360*6
    setRotate(deg) {
      let rest = deg - 360
      return rest > 360 * 6 ? this.setRotate(rest) : deg
    },
    getRandomInt(min, max) {
      // 向上收
      min = Math.ceil(min)
      // 向下收
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min)) + min //不含最大值，含最小值
    },

    // 动画兼容
    whichTransitionEvent() {
      let el = document.createElement('span'),
        transitions = {
          transition: 'transitionend',
          OTransition: 'oTransitionEnd',
          MozTransition: 'transitionend',
          WebkitTransition: 'webkitTransitionEnd',
        }
      for (let t in transitions) {
        if (el.style[t] !== undefined) {
          return transitions[t]
        }
      }
      el = null
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
    // schedule() {
    //   uni.getEnv((res) => {
    //     if (Object.keys(res)[0] === 'h5') {
    //       Toast('该活动仅在app开发,请下载APP')
    //     } else {
    //       uni.postMessage({
    //         data: {
    //           urlApply: 'turntable'
    //         }
    //       })
    //     }
    //   })
    // }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.liveLottery {
  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        > span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: #684728;
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        > span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}

.turntable-box {
  width: calc(100% - 10px);
  position: relative;
  margin: 0 5px;
  padding-bottom: 20px;

  .bgimg {
    width: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .turntable {
    position: absolute;
    top: 185px;
    left: 50%;
    transform: translateX(-50%);
    width: 351px;
    height: 300px;
    background: url('https://begood.gz.bcebos.com/h5/webviewImg/pcbb/turntable-wbg.png') no-repeat;
    background-size: 100% 100%;

    .zhuanpanimg {
      position: absolute;
      top: 32px;
      left: 58px;
      width: 236px;
      height: 236px;
    }

    .turntablebtn {
      position: absolute;
      top: 96px;
      left: 146px;
      width: 61.28px;
      height: 80px;
      cursor: pointer;
    }
  }
}

.content-text {
  text-align: center;
  padding: 20px 0;
  color: #333;
  font-weight: 400 !important;
}

.van-dialog__header {
  color: #333;
  font-weight: 400;
}

.prizedrawing {
  width: 200px;
  height: 25px;
  background: #7b4aff;
  box-shadow: 0px 3px 7px 0px #6f26bd;
  border-radius: 19px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.activebtn {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
  margin-left: 10px;
  background: linear-gradient(0deg, #2a6afd, #63c6ff);
  border-radius: 11px;
}

.activebtn2 {
  background: #aadbff !important;
}
</style>
